<template>
    <div class="differences full-width" v-if="translates && translates[langUrl]">
        <div
            class="differences__main-image">
            <div class="differences__main-image__opacity"></div>
            <div class="differences__main-image__container">
                <div class="differences__main-image__container__text">
                    <div class="differences__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                </div>
            </div>
        </div>
        
        <div class="differences__main-container__container">
            <div class="differences__main-container">
                <div class="differences__main-container__second__title">{{ translates[langUrl].block1_title[lang] }}</div>
                <div class="differences__block differences__block__second">
                    <div class="differences__block__picture differences__block__picture__1" v-if="!isDesktop"></div>
                    <div class="differences__block__text-block">
                        <div class="differences__block__text-block__text">
                            <div class="differences__block__text-block__description" v-html="translates[langUrl].block1_item1_text[lang]"></div>
                        </div>
                    </div>
                    <div class="differences__block__picture differences__block__picture__1" v-if="isDesktop"></div>
                </div>
                <div class="differences__block differences__block__second">
                    <div class="differences__block__text-block">
                        <div v-if="isDesktop" class="differences__block__text-block__text">
                            <div class="differences__block__text-block__description" v-html="translates[langUrl].block1_item2_text[lang]"></div>
                        </div>
                    </div>
                    <div class="differences__block__picture differences__block__picture__2"></div>
                    <div v-if="!isDesktop" class="differences__block__text-block__text differences__block__text-block__text-first">
                        <div v-if="!isDesktop" class="differences__block__text-block__description" v-html="translates[langUrl].block1_item2_text[lang]"></div>
                    </div>
                </div>
                <div class="differences__block differences__block__second">
                    <div class="differences__block__text-block">
                        <div v-if="isDesktop" class="differences__block__text-block__text">
                            <div class="differences__block__text-block__description" v-html="translates[langUrl].block1_item3_text[lang]"></div>
                            <!-- <BaseButton
                                class="button-fill differences__block__text-block__button"
                                @click="goTo('blogger-registration')"
                            >{{ translates[langUrl].button_bloggerRegistration[lang] }}</BaseButton> -->
                        </div>
                    </div>
                    <div class="differences__block__picture differences__block__picture__3"></div>
                    <div v-if="!isDesktop" class="differences__block__text-block__text differences__block__text-block__text-first">
                        <div class="differences__block__text-block__description" v-html="translates[langUrl].block1_item3_text[lang]"></div>
                    </div>
                </div>
                <BaseButton
                    class="button-fill differences__main-container__button"
                    @click="goTo('trip-list')"
                    style="margin-botton: 80px;"
                >{{ translates[langUrl].button_tours[lang] }}</BaseButton>
            </div>
        </div>
        <div class="differences__main-container__second">
            <div class="differences__main-container__second__container">
                <!-- <div class="differences__main-container__second__title">{{ translates[langUrl].secondBlock_title[lang] }}</div> -->
                <div class="differences__block differences__block__second">
                    <div class="differences__block__text-block">
                            <div class="differences__block__text-block__title">{{ translates[langUrl].block2_item1_title[lang] }}</div>
                            <div class="differences__block__text-block__description" v-html="translates[langUrl].block2_item1_text[lang]"></div>
                    </div>
                    <div class="differences__block__picture differences__block__picture__4"></div>
                </div>
                <div class="differences__block differences__block__second">
                    <div class="differences__block__text-block">
                        <div class="differences__block__text-block__title">{{ translates[langUrl].block2_item2_title[lang] }}</div>
                        <div class="differences__block__text-block__description" v-html="translates[langUrl].block2_item2_text[lang]"></div>
                    </div>
                    <div class="differences__block__picture differences__block__picture__5"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS } from '@/vars';

    import BaseButton from '../components/common/BaseButton.vue';
    import MobilePreview from '../components/common/trip/PreviewCard';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'differences',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle? this.translates[this.langUrl].browserTitle[this.lang]: ''
            }
        },
        components: {
            BaseButton,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            isHosting: true,
            langUrl: '/differences'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            this.isDesktop = !isMobile();
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            hosting() {
                this.isHosting = true
            },
            notHosting() {
                this.isHosting = false
            }
        },
    };
</script>

<style lang="scss">
.differences {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/benefits/head.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
        }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 254px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1160px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    margin-top: 115px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
        @media all and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
        &__container {
            width: 100%;
            background: #F6F7F9;
            padding: 80px 0;
            @media all and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                padding: 40px 0;
            }
        }
        &__second {
            padding: 80px 0;
            @media all and (max-width: 768px) {
                padding: 60px 0 20px;
            }
            &__title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                text-align: center;
                color: #273155;
                margin-bottom: 80px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 60px;
                }
            }
            &__container {
                max-width: 1280px;
                margin: 0 auto;
                @media all and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &__button {
            margin-left: calc(50% - 97px);
            @media all and (max-width: 768px) {
                margin: 0 auto;
            }
        }
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 80px;
        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
        &__second {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 80px;
            &:nth-of-type(2n) {
                display: flex;
                flex-direction: row;
            }
            @media all and (max-width: 768px) {
                margin-bottom: 0;
            }
        }
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 0;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__text-block {
            display: flex;
            flex-direction: column;
            max-width: 500px;
            align-self: center;
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                flex-direction: column;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
                margin: 0 20px;
                &-first{
                    @media all and (max-width: 768px) {
                        width: calc(100% - 40px);
                    }
                }
            }
            &__title {
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 130%;
                }
            }
            &__description {
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    margin: 0 auto 40px;
                    width: calc(100% - 40px);
                }
            }
        }
        &__button {
            margin: 0 auto 0 0;
        }
        &__picture {
            min-width: 600px;
            height: 430px;
            background-size: 110%;
            background-position: center;
            border-radius: 10px;
            position: relative;
            @media all and (max-width: 1200px) {
                min-width: 500px;
                height: 355px;
            }
            @media all and (max-width: 950px) {
                min-width: 400px;
                height: 290px;
            }
            @media all and (max-width: 768px) {
                min-width: calc(100% - 40px);
                width: calc(100% - 40px);
                height: 220px;
                margin: 0 auto 40px;
            }
            &__1 {
                background-image: url(../assets/benefits/1.jpg);
                margin-bottom: 0;
                border-radius: 10px 10px 0 0;
                @media all and (max-width: 768px) {
                    margin-bottom: 20px;
                    border-radius: 10px;
                }
            }
            &__2 {
                background-image: url(../assets/benefits/2.jpg);
            }
            &__3 {
                background-image: url(../assets/benefits/3.jpg);
            }
            &__4 {
                background-image: url(../assets/benefits/4.jpg);
            }
            &__5 {
                background-image: url(../assets/benefits/5.jpg);
            }
        }
    }
}

</style>